@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


@tailwind base;
@tailwind components;
@tailwind utilities;

.qa{
   @apply text-left border-b py-5 w-full
}
.q{
    @apply text-4xl py-1 pr-4
}
.a{
    @apply text-4xl py-2 pr-4
}
.question{
    @apply flex items-center font-medium text-lg
}
.answer{
    @apply flex items-center
}
.footerlink{
    @apply hover:text-black cursor-pointer py-1
}