.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.flow-content>*+* {
  margin-top: 1.3rem;
}

.direction {
  flex-direction: column;
  width: 30%;
  min-height: 150px;
  justify-content: space-evenly;
  display: flex;
  gap: 10px;
}

.direction h1 {
  font-family: 'Montserrat', sans-serif;
  font-size: calc(1vw + 24px);
  line-height: 125%;
  color: #338FC9;
}

.stylized-hr {
  height: 3px;
  background-color: #5089AC;
  border: none;
  width: 30%;
}

.form-why {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  border: 1px solid #929292;
  min-height: 100px;
  border-radius: 6px;
  font-weight: 400;
  font-size: 18px;
  line-height: 29px;
}

.comment {
  width: 100%;
  height: 150px;
  resize: none;
  border: 1px solid #929292;
  border-radius: 6px;
  padding: 10px;
}

.center-it {
  justify-content: space-between;
  align-items: center;
}

.forms {
  flex-grow: 2;
  max-width: 30%;
  z-index: 12;
}

.btn-send {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 10px;
  color: #ffffff;
  background: #338FC9;
  border-radius: 6px;
  min-width: calc(25% + 75px);
  cursor: pointer;
  flex-grow: 2;
}

.btn-cancel {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 10px;
  color: #929292;
  background: none;
  border: 1px solid #929292;
  border-radius: 6px;
  min-width: calc(25% + 75px);
  cursor: pointer;
  flex-grow: 2;
}

.full {
  height: calc(75vh - 106px);
  padding: 0px 10% 0px 10%;
}

.background-blue-panda {
  position: relative;
  bottom: 30px;
  height: 25vh;
  width: 100%;
  background-color: #338FC9;
}

.panda-absolute {
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: calc(140px + 35%);
}

.gapy {
   gap: 15px;
}

@media only screen and (max-width: 850px)  {
  .full {
    flex-direction: column;
    padding: 0px 2% 0px 2%;
  }
  .forms {
    max-width: 100%;
    width: 85%;
  }
  .stylized-hr {
    width: 8%;
  }
}